import {
	DDDDMMMDO_FORMAT, V_CALENDAR_CONFIG as calendarConfig,
	YMD_FORMAT,
} from '@/settings/Dates';

export default {
	data() {
		return {
			ordinalDateFormat: DDDDMMMDO_FORMAT,
			calendarConfig,
			checkInDate: null,
			checkOutDate: null,
			roomType: null,
			errorMessage: '',
			paidByCompany: 0,
			nights: 0,
			themeName: process.env.VUE_APP_THEME,
		};
	},
	computed: {
		ticketNames() {
			const names = [`${this.ticketAttributes.first_name} ${this.ticketAttributes.last_name}`, ...this.ticketAttributes.related_tickets.map((ticket) => `${ticket.first_name} ${ticket.last_name}`)];
			return names.join(', ');
		},
		ticketNumbers() {
			const numbers = [this.ticketAttributes.ticket_number, ...this.ticketAttributes.related_tickets.map((ticket) => ticket.ticket_number)];
			return numbers.join(', ');
		},
		computedCheckInDates() {
			return this.getDatesInRange(this.datesInfo.startCheckInDate.toDate(), this.eventDate().toDate());
		},
		computedCheckOutDates() {
			const startCheckoutDate = this.eventDate().add(this.datesInfo.fixedNights, 'days');
			return this.getDatesInRange(startCheckoutDate.toDate(), this.datesInfo.endCheckoutDate.toDate());
		},
		datesInfo() {
			try {
				const data = this.metaHotelInformation;
				return {
					eventDate: this.$moment(data.event_date, YMD_FORMAT).toDate(),
					startCheckInDate: this.$moment(data.start_check_in_date, YMD_FORMAT),
					endCheckoutDate: this.$moment(data.end_check_out_date, YMD_FORMAT),
					fixedNights: data.fixed_nights,
					extendedNights: data.extended_nights,
				};
			} catch (error) {
				return {};
			}
		},
		reservationDatesAttrs() {
			// const normalDates = this.getDatesInRange(this.eventDate().toDate(), this.eventDate().add(this.datesInfo.fixedNights - 1, 'days').toDate());
			const startDate = this.$moment(this.checkInDate);
			const endDate = this.$moment(this.checkOutDate);
			// eslint-disable-next-line vue/no-side-effects-in-computed-properties
			this.nights = endDate.diff(startDate, 'days');
			// eslint-disable-next-line vue/no-side-effects-in-computed-properties
			this.paidByCompany = this.ticketAttributes.reservation_nights;
			// eslint-disable-next-line vue/no-side-effects-in-computed-properties
			this.errorMessage = this.nights !== this.paidByCompany
				? this.translate('check_out_info', { days: this.paidByCompany })
				: '';

			if (this.errorMessage.length > 0) {
				return [];
			}

			// const reservationDates = [
			// 	{
			// 		key: 'company_payed',
			// 		highlight: {
			// 			color: 'green',
			// 			fillMode: 'solid',
			// 			contentClass: 'italic text-white',
			// 		},
			// 		popover: {
			// 			label: this.translate('paid_by_company'),
			// 		},
			// 		dates: this.ticketAttributes.is_extended ? this.calculateExtended(endDate) : normalDates,
			// 		order: 1,
			// 	},
			// ];
			const reservationDates = [];

			if (startDate.isValid() && endDate.isValid()) {
				const dates = this.getStartAndEndDate(this.getDatesInRange(startDate.toDate(), endDate.toDate()));
				reservationDates.push({
					key: 'all_dates',
					highlight: {
						color: 'orange',
						fillMode: 'light',
						contentClass: 'italic',
					},
					dates,
					order: 0,
				});

				reservationDates.push({
					key: 'check_in',
					dot: {
						color: 'orange',
						fillMode: 'light',
						contentClass: 'italic',
					},
					popover: {
						label: this.translate('check_in'),
					},
					dates: dates.start,
					order: 2,
				});

				reservationDates.push({
					key: 'check_out',
					dot: {
						color: 'orange',
						fillMode: 'light',
						contentClass: 'italic',
					},
					popover: {
						label: this.translate('check_out'),
					},
					dates: dates.end,
					order: 2,
				});
			}

			return reservationDates;
		},
	},
	methods: {
		eventDate() {
			return this.$moment(this.datesInfo.eventDate);
		},
		getDatesInRange(startDate, endDate) {
			const date = new Date(startDate.getTime());
			const dates = [];
			while (date <= endDate) {
				dates.push(new Date(date));
				date.setDate(date.getDate() + 1);
			}
			return dates;
		},
		calculateExtended(endDate) {
			let extendedDates = this.getDatesInRange(this.eventDate().toDate(), this.eventDate().add(this.datesInfo.extendedNights - 1, 'days').toDate());
			while ((endDate.isSameOrBefore(extendedDates[extendedDates.length - 1]))) {
				extendedDates = extendedDates.map((date) => this.$moment(date).add(-1, 'days').toDate());
			}
			return extendedDates;
		},
		getStartAndEndDate(range) {
			const dateRange = range.map((date) => this.$moment(date));
			return {
				start: this.$moment.min(dateRange).toDate(),
				end: this.$moment.max(dateRange).toDate(),
			};
		},
	},
};
