<template>
	<div class="animated fadeIn">
		<b-row>
			<b-col class="col-12">
				<div class="table-responsive">
					<table class="table table-hover table-striped text-nowrap">
						<thead>
							<tr>
								<th class="border-top-0">
									#
								</th>
								<th
									v-if="admin.includes($user.details().type)"
									class="border-top-0 pointer"
									@click="sortByField('user_id')">
									{{ translate('user_id') }}
									<sort field="user_id" />
								</th>
								<th
									v-if="admin.includes($user.details().type)"
									class="border-top-0 pointer"
									@click="sortByField('username')">
									{{ translate('username') }}
									<sort field="username" />
								</th>
								<th
									class="border-top-0 pointer"
									@click="sortByField('ticket_number')">
									{{ translate('ticket_number') }}
									<sort field="ticket_number" />
								</th>
								<th
									class="border-top-0 pointer"
									@click="sortByField('code_name')">
									{{ translate('event') }}
									<sort field="code_name" />
								</th>
								<!-- <th
									v-if="!admin.includes($user.details().type)"
									class="border-top-0">
									{{ translate('check_in_date') }}
								</th>
								<th
									v-if="!admin.includes($user.details().type)"
									class="border-top-0">
									{{ translate('check_out_date') }}
								</th> -->
								<th
									class="border-top-0 pointer"
									@click="sortByField('first_name')">
									{{ translate('first_name') }}
									<sort field="first_name" />
								</th>
								<th
									class="border-top-0 pointer"
									@click="sortByField('last_name')">
									{{ translate('last_name') }}
									<sort field="last_name" />
								</th>
								<!-- <th
									class="border-top-0 pointer"
									@click="sortByField('guest_number')">
									{{ translate('guest_number') }}
									<sort field="guest_number" />
								</th> -->
								<th
									v-if="admin.includes($user.details().type)"
									class="border-top-0">
									{{ translate('mobile_number') }}
								</th>
								<!-- <th
									class="border-top-0">
									{{ translate('attendee_type') }}
								</th>
								<th
									class="border-top-0">
									{{ translate('companion_id') }}
								</th> -->
								<th
									v-if="admin.includes($user.details().type)"
									class="border-top-0">
									{{ translate('permanent_rank') }}
								</th>
								<th
									v-if="admin.includes($user.details().type)"
									class="border-top-0">
									{{ translate('rising_star') }}
								</th>
								<th
									v-if="admin.includes($user.details().type)"
									class="border-top-0">
									{{ translate('email') }}
								</th>
								<th
									v-if="admin.includes($user.details().type)"
									class="border-top-0">
									{{ translate('country') }}
								</th>
								<th
									v-if="admin.includes($user.details().type)"
									class="border-top-0">
									{{ translate('country_code') }}
								</th>
								<th
									v-if="admin.includes($user.details().type)"
									class="border-top-0">
									{{ translate('region') }}
								</th>
								<!-- <th
									class="border-top-0">
									{{ translate('room') }}
								</th> -->
								<!-- <th
									v-if="admin.includes($user.details().type)"
									class="border-top-0">
									{{ translate('check_in_date') }}
								</th>
								<th
									v-if="admin.includes($user.details().type)"
									class="border-top-0">
									{{ translate('check_out_date') }}
								</th> -->
								<th
									class="border-top-0 pointer"
									@click="sortByField('order_id')">
									{{ translate('order_id') }}
									<sort field="order_id" />
								</th>
								<th
									class="border-top-0 pointer"
									@click="sortByField('payment_status')">
									{{ translate('payment_status') }}
									<sort field="payment_status" />
								</th>
								<th
									v-if="admin.includes($user.details().type)"
									class="border-top-0 pointer"
									@click="sortByField('organization')">
									{{ translate('organization') }}
									<sort field="organization" />
								</th>
								<!-- <th class="border-top-0">
									{{ translate('remaining_edits') }}
								</th>
								<th class="border-top-0">
									{{ admin.includes($user.details().type) ? translate('remaining_transfers') : translate('transferable') }}
								</th> -->
								<th
									class="border-top-0 pointer"
									@click="sortByField('created_at')">
									{{ translate('purchase_date') }}
									<sort field="created_at" />
								</th>
								<th class="border-top-0 text-center">
									{{ translate('options') }}
								</th>
							</tr>
						</thead>
						<tbody v-if="!loading && hasData">
							<tr
								v-for="(item, index) in data"
								:key="index">
								<td class="align-middle">
									{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
								</td>
								<td
									v-if="admin.includes($user.details().type)"
									class="align-middle">
									<span
										class="pointer badge badge-primary"
										@click="toDashboard({ distributorId: item.attributes.user_id.toString() })">
										{{ item.attributes.user_id }}
									</span>
								</td>
								<td
									v-if="admin.includes($user.details().type)"
									class="align-middle">
									{{ item.attributes.username }}
								</td>
								<td class="align-middle">
									{{ item.attributes.ticket_number }}
								</td>
								<td class="align-middle">
									{{ translate(item.attributes.event.event_code_name) }}
								</td>
								<!-- <template v-if="!admin.includes($user.details().type)">
									<template v-if="!item.attributes.can_edit_hotel_information || item.attributes.check_in_date && item.attributes.check_out_date">
										<td class="align-middle">
											{{ item.attributes.check_in_date ? $moment(item.attributes.check_in_date).format(dateFormat) : translate('NA') }}
										</td>
										<td class="align-middle">
											{{ item.attributes.check_out_date ? $moment(item.attributes.check_out_date).format(dateFormat) : translate('NA') }}
										</td>
									</template>
									<template v-else>
										<td colspan="2">
											<b-alert
												v-if="item.attributes.remaining_days_to_choose"
												variant="danger"
												class="m-0"
												show>
												<center>
													{{ translate('left_days_alert', { days: item.attributes.remaining_days_to_choose }) }}
													<span
														v-b-tooltip.hover
														:title="translate('left_days_tooltip', { days: item.attributes.remaining_days_to_choose })"
														class="text-small">
														<em class="fas fa-lg fa-info-circle" />
													</span>
												</center>
											</b-alert>
										</td>
									</template>
								</template> -->
								<td class="align-middle">
									{{ item.attributes.first_name }}
								</td>
								<td class="align-middle">
									{{ item.attributes.last_name }}
								</td>
								<!-- <td class="text-center align-middle">
									{{ item.attributes.guest_number }}
								</td> -->
								<td
									v-if="admin.includes($user.details().type)"
									class="align-middle">
									{{ item.attributes.mobile_number }}
								</td>
								<!-- <td class="align-middle">
									{{ item.attributes.attendant_type ? translate(item.attributes.attendant_type) : '' }}
								</td>
								<td class="align-middle">
									<span
										v-if="item.attributes.attendant_id"
										class="badge badge-primary">
										{{ item.attributes.attendant_id }}
									</span>
								</td> -->
								<td
									v-if="admin.includes($user.details().type)"
									class="align-middle">
									{{ item.attributes.rank ? translate(item.attributes.rank) : '' }}
								</td>
								<td
									v-if="admin.includes($user.details().type)"
									class="align-middle">
									{{ item.attributes.is_rising_star === null ? '' : translate(`${!!item.attributes.is_rising_star}`) }}
								</td>
								<td
									v-if="admin.includes($user.details().type)"
									class="align-middle">
									{{ item.attributes.email }}
								</td>
								<td
									v-if="admin.includes($user.details().type)"
									class="align-middle">
									{{ translate(item.attributes.country_code.toLowerCase()) }}
								</td>
								<td
									v-if="admin.includes($user.details().type)"
									class="align-middle">
									{{ item.attributes.country_code }}
								</td>
								<td
									v-if="admin.includes($user.details().type)"
									class="align-middle">
									{{ item.attributes.region_name }}
								</td>
								<!-- <td class="align-middle">
									{{ item.attributes.room_type ? translate(roomCode(item.attributes.room_type)) : '' }}
								</td>
								<template v-if="admin.includes($user.details().type)">
									<template v-if="!item.attributes.can_edit_hotel_information || item.attributes.check_in_date && item.attributes.check_out_date">
										<td class="align-middle">
											{{ item.attributes.check_in_date ? $moment(item.attributes.check_in_date).format(dateFormat) : translate('NA') }}
										</td>
										<td class="align-middle">
											{{ item.attributes.check_out_date ? $moment(item.attributes.check_out_date).format(dateFormat) : translate('NA') }}
										</td>
									</template>
									<template v-else>
										<td colspan="2">
											<b-alert
												v-if="item.attributes.remaining_days_to_choose"
												variant="danger"
												class="m-0"
												show>
												<center>
													{{ translate('left_days_alert', { days: item.attributes.remaining_days_to_choose }) }}
													<span
														v-b-tooltip.hover
														:title="translate('left_days_tooltip', { days: item.attributes.remaining_days_to_choose })"
														class="text-small">
														<em class="fas fa-lg fa-info-circle" />
													</span>
												</center>
											</b-alert>
										</td>
									</template>
								</template> -->
								<td class="align-middle text-center">
									{{ item.attributes.order_id }}
								</td>
								<td class="align-middle">
									<b-alert
										:variant="getPaymentStatusColor(item.attributes.payment_status)"
										class="m-0"
										show>
										<center>
											{{ translate(item.attributes.payment_status) }}
										</center>
									</b-alert>
								</td>
								<td
									v-if="admin.includes($user.details().type)"
									class="align-middle">
									{{ item.attributes.organization }}
								</td>
								<!-- <td class="align-middle text-center">
									{{ item.attributes.can_edit ? item.attributes.remaining_edits : 0 }}
								</td>
								<td class="align-middle text-center">
									{{ admin.includes($user.details().type) ? (item.attributes.can_transfer ? item.attributes.remaining_transfers : 0) : translate(`${!!item.attributes.can_transfer}`) }}
								</td> -->
								<td class="align-middle">
									{{ $moment(item.attributes.created_at.date).format(datetimeFormat) }}
								</td>
								<td class="text-center align-middle">
									<b-button
										v-if="item.attributes.is_enabled"
										v-b-tooltip.hover
										:title="translate('view_thing', {name: translate('ticket')})"
										variant="primary"
										class="mr-1 bg-primary-alt"
										@click="showTicket(item)">
										<i class="fa fa-eye" />
									</b-button>
									<b-button
										v-if="!admin.includes($user.details().type) && canChooseReservationDates(item.attributes)"
										v-b-tooltip.hover.html.left
										:title="translate(item.attributes.hotel_information_locked_in || admin.includes($user.details().type) ? 'view_thing' : 'update_thing', {name: translate('hotel_information')})"
										variant="primary"
										class="mr-1 bg-primary-alt"
										@click="showTicketHotelInformation(item)">
										<i class="fa fa-building" />
									</b-button>
									<b-button
										v-if="$can('event_tickets', 'update_ticket_data') || $can('event_tickets', 'transfer') || $can('event_tickets', 'update_notes') || distributorCanUpdate(item)"
										v-b-tooltip.hover
										:title="translate('update_thing', {name: translate('ticket')})"
										variant="primary"
										class="mr-1 bg-primary-alt"
										@click="$router.push({ name: editRoute, params: { ticketId: item.id } });">
										<i class="fa fa-pen" />
									</b-button>
									<b-button
										v-if="$can('event_tickets', 'delete')"
										v-b-tooltip.hover
										:title="translate('delete_thing', {name: translate('ticket')})"
										variant="primary"
										class="mr-1 bg-primary-alt"
										@click="deleteEventTicket(item.id)">
										<i class="fa fa-trash-alt" />
									</b-button>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<is-loading
					:loading-label="translate('loading')"
					:no-data-label="translate('data_not_found')"
					:loading="loading"
					:has-data="hasData" />
				<b-pagination
					v-if="pagination.total_pages > 1"
					v-model="pagination.current_page"
					:total-rows="pagination.total"
					:per-page="pagination.per_page"
					align="center"
					@change="getDataFiltered" />
			</b-col>
		</b-row>
		<b-modal
			ref="ticketModal"
			hide-footer
			no-close-on-esc
			no-close-on-backdrop
			header-class="mx-auto w-100 bg-dark text-white"
			centered
			@hide="showModal = false">
			<template v-slot:modal-header>
				<h5 class="modal-title text-white">
					{{ translate('ticket_information') }}
				</h5>
				<button
					type="button"
					aria-label="Close"
					class="close text-white"
					@click="showModal = false">
					×
				</button>
			</template>
			<div
				v-if="!loading"
				class="row no-gutters justify-content-center">
				<div
					class="col-12">
					<div class="my-2">
						<template v-if="ticket">
							<div class="text-center mb-4">
								<qrcode-vue
									:value="ticket.attributes.ticket_number"
									size="150" />
							</div>
							<p class="mb-2">
								<strong>{{ translate('ticket_owner') }}:</strong> {{ ticket.attributes.first_name }} {{ ticket.attributes.last_name }}
							</p>
							<p class="my-2">
								<strong>{{ translate('ticket_number') }}:</strong> {{ ticket.attributes.ticket_number }}
							</p>
							<p class="my-2">
								<strong>{{ translate('event') }}:</strong> {{ translate(ticket.attributes.event.event_code_name) }}
							</p>
							<p class="my-2">
								<strong>{{ translate('user') }}:</strong> {{ ticket.attributes.username }} ({{ ticket.attributes.user_id }})
							</p>
						</template>
					</div>
				</div>
				<div class="col col-12 no-gutters justify-content-end text-right">
					<button
						type="button"
						aria-label="Close"
						style="max-width: 200px"
						class="btn btn-primary mt-3"
						@click="showModal = false">
						{{ translate('close') }}
					</button>
				</div>
			</div>
		</b-modal>
		<b-modal
			ref="hotelInformationModal"
			hide-footer
			no-close-on-esc
			no-close-on-backdrop
			header-class="mx-auto w-100 bg-dark text-white"
			centered
			@hide="showHotelInformationModal = false">
			<template v-slot:modal-header>
				<h5 class="modal-title text-white capitalize-first-letter">
					{{ translate('hotel_information') }}
				</h5>
				<button
					type="button"
					aria-label="Close"
					class="close text-white"
					@click="showHotelInformationModal = false">
					×
				</button>
			</template>
			<div
				v-if="!loading"
				class="row no-gutters justify-content-center">
				<div
					class="col-12">
					<div class="mt-2">
						<template v-if="ticket && step === 0">
							<p class="my-2">
								<strong>{{ translate('user') }}:</strong> {{ ticket.attributes.username }} ({{ ticket.attributes.user_id }})
							</p>
							<p class="my-2">
								<strong>{{ translate(ticket.attributes.related_tickets.length > 0 ? 'ticket_names' : 'ticket_name') }}:</strong> {{ ticketNames }}
							</p>
							<p class="my-2">
								<strong>{{ translate('ticket_number') }}:</strong> {{ ticketNumbers }}
							</p>
							<p class="my-2">
								<strong>{{ translate('event') }}:</strong> {{ translate(ticket.attributes.event.event_code_name) }}
							</p>
							<hr>
							<div>
								<b-row>
									<b-col>
										<b-form-group class="form-group">
											<label
												for="check_in_date"
												class="label font-weight-bold">{{ translate('check_in_date') }}
												<span class="text-danger">*</span></label>
											<select
												v-if="distributor.includes($user.details().type) && !ticket.attributes.hotel_information_locked_in"
												id="check_in_date"
												v-model="checkInDate"
												name="check_in_date"
												class="form-control">
												<option
													v-for="(date, index) in computedCheckInDates"
													:key="index"
													selected
													:value="date">
													{{ $moment(date).format(ordinalDateFormat) }}
												</option>
											</select>
											<p
												v-else
												class="m-0">
												<b>{{ $moment(checkInDate).format(ordinalDateFormat) }}</b>
											</p>
										</b-form-group>
									</b-col>
									<b-col>
										<b-form-group class="form-group">
											<label
												for="check_out_date"
												class="label font-weight-bold">{{ translate('check_out_date') }}
												<span class="text-danger">*</span></label>
											<!-- <select
												v-if="distributor.includes($user.details().type) && !ticket.attributes.hotel_information_locked_in"
												id="check_out_date"
												v-model="checkOutDate"
												name="check_out_date"
												class="form-control">
												<option
													v-for="(date, index) in computedCheckOutDates"
													:key="index"
													selected
													:value="date">
													{{ $moment(date).format(ordinalDateFormat) }}
												</option>
											</select> -->
											<p
												class="m-0">
												<b>{{ $moment(checkOutDate).format(ordinalDateFormat) }}</b>
											</p>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row>
									<b-col>
										<b-alert
											v-if="errorMessage.length > 0"
											show
											variant="danger">
											{{ errorMessage }}
										</b-alert>
									</b-col>
								</b-row>
								<b-row v-if="errorMessage.length === 0">
									<b-col class="mb-3">
										<div class="my-1 d-flex justify-content-start align-items-center">
											<div
												class="ml-2"
												style="border-radius: 50%; background-color: rgb(255,115,0); width: 5px; height: 5px" />
											<span class="ml-3 pl-1">{{ translate('check_in_and_check_out_dates') }}</span>
										</div>
										<div class="my-1 d-flex justify-content-start align-items-center">
											<div style="border-radius: 50%; background-color: rgba(255,165,0,0.5); width: 25px; height: 25px" />
											<span class="ml-2">{{ translate('reservation_nights', { nights }) }}</span>
										</div>
										<!-- <div class="my-1 d-flex justify-content-start align-items-center">
											<div style="border-radius: 50%; background-color: green; width: 25px; height: 25px" />
											<span class="ml-2">{{ translate('paid_by_company_nights', { nights: paidByCompany }) }}</span>
										</div> -->
									</b-col>
								</b-row>
							</div>
							<div class="text-center">
								<v-date-picker
									is-inline
									is-expanded
									:value="null"
									:min-date="datesInfo.startCheckInDate.toDate()"
									:max-date="datesInfo.endCheckoutDate.toDate()"
									:disabled-dates="getDatesInRange(datesInfo.startCheckInDate.toDate(), datesInfo.endCheckoutDate.toDate())"
									:locale="language"
									mode="range"
									color="red"
									:attributes="reservationDatesAttrs"
									:formats="calendarConfig" />
							</div>
							<div v-if="ticket.attributes.available_room_types">
								<b-row class="mt-3">
									<b-col cols="5">
										<b-form-group class="form-group">
											<label
												for="room_type"
												class="label font-weight-bold">{{ translate('room_type') }}
												<span class="text-danger">*</span></label>
											<b-form-radio-group
												v-if="distributor.includes($user.details().type) && !ticket.attributes.hotel_information_locked_in"
												v-model="roomType"
												stacked>
												<b-form-radio
													v-for="(room_type, index) in ticket.attributes.available_room_types"
													:key="index"
													:value="room_type">
													{{ translate(`report_${room_type}`) }}
												</b-form-radio>
											</b-form-radio-group>
											<p
												v-else
												class="m-0">
												<b>{{ roomType ? translate(roomType) : '' }}</b>
											</p>
										</b-form-group>
									</b-col>
									<b-col cols="7">
										<div>
											<div class="d-flex align-items-center justify-content-around">
												<div>
													<img
														class="card-img"
														:style="{'max-height': '85px', 'max-width': '85px'}"
														:src="require(`@/assets/images/themes/${themeName}/tickets/${roomType === 'single_room' ? 'kingSize_100x100_orange' : 'kingSize_100x100_gray'}.png`)">
												</div>
												<div>
													<img
														class="card-img"
														:style="{'max-height': '85px', 'max-width': '85px'}"
														:src="require(`@/assets/images/themes/${themeName}/tickets/${roomType === 'double_room' ? 'queenSize_100x100_orange' : 'queenSize_100x100_gray'}.png`)">
												</div>
											</div>
										</div>
									</b-col>
								</b-row>
							</div>
						</template>
						<template v-if="step === 1">
							<p>
								{{ translate('confirm_dates_description') }}
							</p>
							<b>{{ translate('check_in') }}:</b> {{ $moment(checkInDate).format(ordinalDateFormat) }}
							<br>
							<b>{{ translate('check_out') }}:</b> {{ $moment(checkOutDate).format(ordinalDateFormat) }}
							<p class="mt-3">
								<b-alert
									show
									variant="danger">
									{{ translate('confirm_dates_description_2') }}
								</b-alert>
							</p>
						</template>
					</div>
				</div>
				<div class="col col-12 no-gutters justify-content-end text-right">
					<button
						v-if="step === 1"
						type="button"
						style="max-width: 200px"
						class="btn btn-secondary mt-3 mr-2"
						@click="step = 0">
						{{ translate('cancel') }}
					</button>
					<button
						v-if="!hideConfirm"
						type="button"
						style="max-width: 200px"
						class="btn btn-primary mt-3"
						:disabled="disableConfirm"
						@click="confirmDates()">
						{{ translate(step === 0 ? 'save_changes' : 'confirm_dates') }}
						<em
							v-if="hotelRevervation.data.loading"
							class="fa fa-fw fa-spinner fa-pulse" />
					</button>
				</div>
			</div>
		</b-modal>
	</div>
</template>
<script>

import QrcodeVue from 'qrcode.vue';
import { PAGINATION } from '@/settings/RequestReply';
import {
	Grids, Users, Tooltip, Events, EventTickets as Tickets, Common, Ranks, Countries,
} from '@/translations';
import FiltersParams from '@/mixins/FiltersParams';
import DashboardRedirect from '@/mixins/DashboardRedirect';
import EventTickets from '@/util/EventTickets';
import {
	YMDHMS_FORMAT,
	YMD_FORMAT,
	DDDDMMMDO_FORMAT,
} from '@/settings/Dates';
import {
	admin, distributor,
} from '@/settings/Roles';
import HotelInformation from '@/views/Orders/mixins/HotelInformation';

export default {
	name: 'EventTicketsAll',
	messages: [Grids, Users, Events, Tickets, Tooltip, Common, Ranks, Countries],
	components: { QrcodeVue },
	mixins: [FiltersParams, DashboardRedirect, HotelInformation],
	data() {
		return {
			datetimeFormat: YMDHMS_FORMAT,
			dateFormat: YMD_FORMAT,
			ordinalDateFormat: DDDDMMMDO_FORMAT,
			admin,
			distributor,
			tickets: new EventTickets(),
			deleteTicket: new EventTickets(),
			hotelRevervation: new EventTickets(),
			alert: new this.$Alert(),
			editRoute: 'UserEditEventTicket',
			showModal: false,
			ticket: null,
			showHotelInformationModal: false,
			step: 0,
		};
	},
	computed: {
		ticketAttributes() {
			return this.ticket.attributes;
		},
		loading() {
			return !!this.tickets.data.loading;
		},
		pagination() {
			return this.tickets.data.pagination;
		},
		metaHotelInformation() {
			return this.tickets.data.response.data.meta.hotel_information;
		},
		data() {
			try {
				const { data } = this.tickets.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
		hideConfirm() {
			try {
				return (this.ticket.attributes.check_in_date && this.ticket.attributes.check_out_date) || !distributor.includes(this.$user.details().type);
			} catch (error) {
				return false;
			}
		},
		disableConfirm() {
			try {
				return this.errorMessage.length > 0 || this.hotelRevervation.data.loading;
			} catch (error) {
				return true;
			}
		},
	},
	watch: {
		showModal(show) {
			if (show) this.$refs.ticketModal.show();
			else this.$refs.ticketModal.hide();
		},
		showHotelInformationModal(show) {
			if (show) this.$refs.hotelInformationModal.show();
			else this.$refs.hotelInformationModal.hide();
		},
		checkInDate() {
			this.checkOutDate = this.$moment(this.checkInDate).add(this.ticket.attributes.reservation_nights, 'days').toDate();
		},
	},
	mounted() {
		this.tickets.getTickets();

		if (admin.includes(this.$user.details().type)) {
			this.editRoute = 'EditEventTicket';
		}
	},
	methods: {
		confirmDates() {
			if (this.step === 0) {
				this.step = 1;
				return;
			}

			this.hotelRevervation.hotelReservation(this.ticket.id, {
				check_in_date: this.$moment(this.checkInDate).format(YMD_FORMAT),
				check_out_date: this.$moment(this.checkOutDate).format(YMD_FORMAT),
				room_type: this.roomType,
			}).then(() => {
				this.alert.toast('success', this.translate('ticket_updated'));
				this.tickets.getTickets();
				this.$refs.hotelInformationModal.hide();
			}).catch(() => {
				const errorName = 'default_error_message';
				this.alert.toast('error', this.translate(errorName));
			});
		},
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}

			const { query } = this.$route;
			const options = { ...query, ...this.filters };

			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}

			Object.keys(options).forEach((item) => {
				if (!options[item]) {
					delete options[item];
				}
			});

			this.$router.push({ name: this.$route.name, query: options });
		},
		getPaymentStatusColor(status) {
			if (['paid'].includes(status)) {
				return 'green';
			}

			if (['stopped', 'canceled'].includes(status)) {
				return 'danger';
			}

			return 'secondary-darker';
		},
		deleteEventTicket(id) {
			const trans = {
				title: this.translate('delete_ticket_title'),
				text: this.translate('password'),
			};
			const options = {
				buttonText: this.translate('ok'),
				cancelButtonText: this.translate('cancel'),
				emptyPasswordMessage: this.translate('password_is_required'),
			};
			this.alert.confirmationWithPassword(trans.title, trans.text, options).then((password) => {
				this.deleteTicket.deleteTicket(id, { password: password.value }).then(() => {
					this.alert.toast('success', this.translate('ticket_deleted'));
					this.tickets.getTickets();
				}).catch(() => {
					let errorName = 'default_error_message';
					if (typeof this.deleteTicket.data.errors.errors.password !== 'undefined') {
						errorName = 'incorrect_password';
						delete this.deleteTicket.data.errors.errors.password;
					}
					this.alert.toast('error', this.translate(errorName));
				});
			}).catch(() => {});
		},
		showTicket(ticket) {
			this.ticket = ticket;
			this.showModal = true;
		},
		showTicketHotelInformation(ticket) {
			this.ticket = ticket;
			this.step = 0;
			this.checkInDate = this.ticket.attributes.check_in_date ? this.$moment(this.ticket.attributes.check_in_date, YMD_FORMAT).toDate() : this.eventDate().toDate();
			this.checkOutDate = this.ticket.attributes.check_out_date ? this.$moment(this.ticket.attributes.check_out_date, YMD_FORMAT).toDate() : this.eventDate().add(this.ticket.attributes.reservation_nights, 'days').toDate();
			this.roomType = this.ticket.attributes.room_type;
			this.showHotelInformationModal = true;
		},
		roomCode(type) {
			if (this.admin.includes(this.$user.details().type)) {
				return `report_${type}`;
			}

			return type;
		},
		distributorCanUpdate(item) {
			return this.distributor.includes(this.$user.details().type) && item.attributes.can_update_ticket && (item.attributes.can_edit || item.attributes.can_transfer);
		},
		canChooseReservationDates(ticket) {
			return ticket.remaining_days_to_choose > 0 && ticket.is_parent && ticket.can_edit_hotel_information && ticket.check_in_date == null && ticket.check_out_date == null;
		},
	},
};
</script>
