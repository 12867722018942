import {
	adminAffiliateDashboardPermissions,
	adminAgencyDashboardPermissions,
	adminCustomerDashboardPermissions,
	adminDashboardPermissions,
} from '@/settings/Dashboard';

export default {
	data() {
		return {};
	},
	methods: {
		toDashboard(params, query = '') {
			const permissionsByUserType = {
				agency: adminAgencyDashboardPermissions,
				distributor: adminDashboardPermissions,
				pre_distributor: adminDashboardPermissions,
				affiliate: adminAffiliateDashboardPermissions,
				customer: adminCustomerDashboardPermissions,
			};

			if (typeof params.userId === 'undefined' && typeof params.distributorId !== 'undefined') {
				params.userId = params.distributorId;
			}

			/* eslint-disable no-restricted-syntax */
			for (const item of permissionsByUserType[query.userType] ?? adminDashboardPermissions) {
				if (this.$can(item.section, item.view)) {
					this.$router.push({ name: item.name, query, params });
					break;
				}
			}
		},
	},
};
