export default {};

export const adminDashboardPermissions = [
	{
		section: 'dashboard',
		view: 'view',
		name: 'AdminDistributorsDashboard',
	},
	{
		section: 'distributors_information',
		view: 'view',
		name: 'DistributorsInformation',
	},
	{
		section: 'orders',
		view: 'view',
		name: 'UsersMainOrders',
	},
	{
		section: 'downloads',
		view: 'view',
		name: 'DistributorsDownloads',
	},
	{
		section: 'commissions',
		view: 'view',
		name: 'DistributorsCommissions',
	},
	{
		section: 'bv',
		view: 'view',
		name: 'DistributorsBV',
	},
	{
		section: 'binary_tree',
		view: 'view',
		name: 'DistributorsBinaryTree',
	},
	{
		section: 'unilevel_summary',
		view: 'view',
		name: 'DistributorsUnilevelSummary',
	},
	{
		section: 'distributor_organization',
		view: 'view',
		name: 'DashboardDistributorOrganization',
	},
	// {
	// 	section: 'autoship_summary',
	// 	view: 'view',
	// 	name: 'DistributorsAutoshipSummary',
	// },
	{
		section: 'user_notes',
		view: 'view',
		name: 'DashboardUserNotes',
	},
];

export const adminAgencyDashboardPermissions = [
	{
		section: 'agency_dashboard',
		view: 'view',
		name: 'AdminAgencyDashboard',
	},
	{
		section: 'distributors_information', // TODO: Change this permission to agencies_information
		view: 'view',
		name: 'AgenciesInformation',
	},
	{
		section: 'orders',
		view: 'view',
		name: 'UsersMainOrders',
	},
	{
		section: 'user_notes',
		view: 'view',
		name: 'DashboardUserNotes',
	},
];

export const adminAffiliateDashboardPermissions = [
	{
		section: 'dashboard',
		view: 'view',
		name: 'AdminAffiliateDashboard',
	},
	{
		section: 'distributors_information', // TODO: Change this permission to affiliates_information
		view: 'view',
		name: 'AffiliatesInformation',
	},
	{
		section: 'orders',
		view: 'view',
		name: 'UsersMainOrders',
	},
	{
		section: 'user_notes',
		view: 'view',
		name: 'DashboardUserNotes',
	},
	{
		section: 'commissions',
		view: 'view',
		name: 'AffiliatesCommissions',
	},
];

export const adminCustomerDashboardPermissions = [
	{
		section: 'dashboard',
		view: 'view',
		name: 'AdminCustomerDashboard',
	},
	{
		section: 'distributors_information', // TODO: Change this permission to customers_information
		view: 'view',
		name: 'CustomersInformation',
	},
	{
		section: 'orders',
		view: 'view',
		name: 'UsersMainOrders',
	},
	{
		section: 'user_notes',
		view: 'view',
		name: 'DashboardUserNotes',
	},
];

export const ADMIN_DASHBOARD_TOP_USERS_COUNT = 10;
export const SALES_BY_STATE_VALID_CORPORATE_COUNTRY = ['US'];
